import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_Novel = () => {
    const seo = {
        metaDesc: 'What is the One Year Rule? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What is the One Year Rule? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What is the One Year Rule?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <p><span style={{ fontWeight: 400 }}>Patented inventions <i>must</i> be different from existing knowledge or previous
                                    inventions, otherwise known as prior art. What is prior art?</span></p>
                                <h2><span style={{ fontWeight: 400 }}><strong>Prior art is</strong>:</span></h2>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>anything in public use or sold in the United
                                        States for more than a year prior to the patent application’s filing date;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>anything publicly known or used by others in this
                                        country before the invention date;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>anything made or built in this country by another
                                        before the invention date;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>prior patents issued more than a year before the
                                        patent’s filing date or any time before the date of invention;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>prior publications dated more than a year before
                                        the patent’s filing date or any time before the date of invention; or</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>U.S. patents with a filing date prior to the
                                        invention date. &nbsp;<a href="http://inventiv.org">What are Invention date and One Year
                                            Rule?</a></span></li>
                                </ul>
                                <p></p>
                                <h2><strong>There are three types of differences:</strong></h2>
                                <p><b>1.physically different</b></p>
                                <p><span style={{ fontWeight: 400 }}>The new invention must have at least one piece of hardware that is physically
                                    different from prior art.&nbsp; This can be an addition or a subtraction of hardware.&nbsp; It may also be
                                    something less obvious than that.&nbsp; For example, a physical difference may be that the invention works
                                    in a different temperature range or a different mode than what was indicated by prior art.&nbsp; These will
                                    all satisfy the novelty criteria for patenting a new invention.</span></p>
                                <p><b>2. new combination</b></p>
                                <p><span style={{ fontWeight: 400 }}>Novelty may include new combinations of prior art.&nbsp; In other words, two
                                    previously patented inventions are prior art.&nbsp; But if they are combined in such a way that is not
                                    already patented, then this new combination is considered novel.&nbsp;&nbsp;</span></p>
                                <p><b>3. new use</b></p>
                                <p><span style={{ fontWeight: 400 }}>A patented invention’s use is described in the claims section of its patent
                                    application.&nbsp; If you think of a new use that isn’t discussed in the patent application, then that new
                                    use satisfies the novelty component.&nbsp; This new use of an already patented invention may be
                                    patentable.&nbsp;</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_Novel;